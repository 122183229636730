import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';

const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { cta, btn, email } = contact;

  return (
    <section id="contact">
      <Container>
        <Title title="Lust mit uns zu arbeiten?" />
        <Fade bottom duration={1000} delay={800} distance="30px">
          <div className="contact-wrapper">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href={email ? `mailto:${email}` : 'https://github.com/cobidev/react-simplefolio'}
            >
              {btn || "Let's Talk"}
            </a>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2492.5937514462657!2d12.405999416011198!3d51.33699163096899!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a6f9bf15a69eb9%3A0xd6c68c5a00ac595f!2sBreite%2012!5e0!3m2!1sde!2sde!4v1605023864951!5m2!1sde!2sde"
            width="400"
            height="300"
            frameBorder="0"
            style={{ border: '05', paddingTop: '50' }}
            allowFullscreen=""
            aria-hidden="false"
            title="GoogleMaps"
          ></iframe>
        </Fade>
      </Container>
    </section>
  );
};

export default Contact;
