import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero';
import About from './About/About';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import CookieConsent, { Cookies } from 'react-cookie-consent';

import { PortfolioProvider } from '../context/context';

import { heroData, aboutData, projectsData, contactData, footerData } from '../mock/data';

function App() {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ hero, about, projects, contact, footer }}>
      <Hero />
      <About />
      <Projects />
      <Contact />
      <Footer />

      <CookieConsent
        enableDeclineButton
        flipButtons
        location="bottom"
        buttonText="Zustimmen"
        declineButtonStyle={{ background: '#5f7063', border: 'solid grey 1px', color: 'grey' }}
        style={{ background: '#5f7063' }}
        declineButtonText="Ablehnen"
        buttonStyle={{
          backgroundColor: '#fff',
          color: '#000',
          fontSize: '13px',
        }}
        cookieName="gatsby-gdpr-google-analytics"
      >
        Diese Website speichert Cookies auf Ihrem Computer. Wir verwenden diese Informationen, um
        Ihr Surf-Erlebnis zu verbessern und anzupassen und für Analysen und Metriken über unsere
        Besucher auf dieser Website. Wenn Sie dies ablehnen, werden Ihre Informationen nicht
        verfolgt, wenn Sie diese Website besuchen. Ein einziger Cookie wird in Ihrem Browser
        verwendet, um Ihre Präferenz, nicht verfolgt zu werden, zu speichern.
      </CookieConsent>
    </PortfolioProvider>
  );
}

export default App;
