import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';

const Footer = () => {
  const { footer } = useContext(PortfolioContext);
  const { networks } = footer;

  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        <div className="social-links">
          {networks &&
            networks.map((network) => {
              const { id, name, url } = network;
              return (
                <a
                  key={id}
                  href={url || 'www.breite12.work'}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={name}
                >
                  <i className={`fa fa-${name} fa-inverse`} />
                </a>
              );
            })}
        </div>
        <p className="footer__text" style={{ color: 'white', fontFamily: 'Raleway' }}>
          Breite Str. 12 <br /> 04317 Leipzig
          <br />
          <a
            href="mailto:hi@breite12.work"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'white' }}
          >
            {' '}
            <br />
            hi@breite12.work
          </a>
        </p>{' '}
        <br />
        <hr />
        <p className="footer__text">
          © {new Date().getFullYear()} -{' '}
          <a href="https://www.instagram.com/k_mag94/" target="_blank" rel="noopener noreferrer">
            Magnus Krause
          </a>
          &nbsp; &amp; &nbsp;
          <a href="https://www.wischn.com" target="_blank" rel="noopener noreferrer">
            Annika Wischnewsky
          </a>
          <br />
          <a href="https://www.wischn.com/datenschutz" target="_blank" rel="noopener noreferrer">
            Datenschutz
          </a>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
